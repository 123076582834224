<template>
  <v-dialog v-model="show" @click:outside="closeDialog" max-width="50%">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>

        <v-container>
          <v-row>
            <!-- Upload input -->
            <v-col cols="12" md="6">
              <v-file-input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                label="Upload XLSX file" v-model="file" @change="previewExcel">
              </v-file-input>
            </v-col>

            <!-- Sample for download, if required. -->
            <v-col cols="12" md="6" class="d-flex justify-end" v-if="sampleUrl">
              <v-btn depressed color="gray" @click="downloadSample">
                <v-icon left>mdi-file-download-outline</v-icon>
                Download Sample
              </v-btn>
            </v-col>

            <!-- Preview table -->
            <v-col cols="12">
              <SSRTable ref="preview-table" :data="table.rows" :schema="previewSchema">
              </SSRTable>
            </v-col>
          </v-row>
        </v-container>  

      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn depressed color="gray" @click="closeDialog">Cancel</v-btn>
        <v-btn depressed color="warning" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>

<script>
import SSRTable from '@/components/SSRTable';

import readXlsxFile from 'read-excel-file';
import axios from 'axios';

export default {
  name: 'UploadPreviewDialog',
  components: {
    SSRTable
  },
  props: {
    show: Boolean,
    title: {
      type: String,
      default: 'Upload & Preview'
    },
    postUrl: String,
    dataKey: String,

    sampleUrl: String,

    successMessage: {
      type: String,
      default: 'Submission succeeded!',
    },
    failMessage: {
      type: String,
      default: 'Submission failed!',
    }
  },

  mounted() {

  },

  methods: {
    closeDialog() {
      this.$emit('update:show', false);
    },

    previewExcel(file) {
      if (!file) {
        // Clear the table.
        this.table.schema = [ ];
        this.table.rows = [ ];
        return;
      }

      readXlsxFile(file)
      .then(rows => {
        this.table.rows = [ ];
        this.table.schema = rows.slice(0, 1)[0];

        // Populate table row data.
        rows.shift();
        rows.forEach(cells => {
          let record = { };
          for (let i=0; i<cells.length; i++) {
            let key = this.table.schema[i];
            record[key] = cells[i];
          }
          this.table.rows.push(record);
        });
        // console.log(this.table.rows);
        // this.$refs['preview-table'].loadData();
        this.table.show = true;
      })
      .catch(err => {
        console.error(`[UploadPreviewDialog] readXlsxFile failed`, err);
        this.$notify({
          text: `Cannot read this file: ${err.message}`,
          type: 'error'
        });
      })
      .finally(() => {
        this.$refs['preview-table'].loadData();
      });
    },

    submit() {
      if (!this.table.rows.length) {
        console.error(`No data to submit.`);
        this.$notify({
          text: `Nothing to submit?`,
          type: 'warn'
        });
        this.closeDialog();
        return;
      }
      if (!this.postUrl || !this.dataKey) {
        console.warn(`No POST URL or data key given.`);
        this.$emit('submit', this.table.rows);
        this.closeDialog();
        return;
      }
        
      let reqBody = { };
      reqBody[this.dataKey] = this.table.rows;
      console.warn(`[UploadPreviewDialog] Will submit via ${this.postUrl}`, reqBody);
      axios.post(this.postUrl, reqBody)
      .then(response => {
        console.log(`[UploadPreviewDialog] submission succeeded.`, response.data);
        this.$notify({
          text: this.successMessage,
          type: 'success'
        });
      })
      .catch(err => {
        console.error(`[UploadPreviewDialog] submission failed.`, err);
        this.$notify({
          text: this.failMessage,
          type: 'error'
        });
      })
      .finally(() => {
        this.closeDialog();
      });
    },

    downloadSample() {
      if (this.sampleUrl)
        window.open(this.sampleUrl);
    }
  },

  data() {
    return {
      file: undefined,

      table: {
        show: false,
        schema: [ ],
        rows: [ ]
      }
    };
  },

  computed: {
    previewSchema() {
      return this.table.schema.map(s => {
        return { value: s, text: s };
      });
    }
  }
}
</script>
