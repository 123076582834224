<template>
  <div>

    <v-dialog v-model="show" width="60%"
      @click:outside="closeDialog">

      <v-card>
        <v-card-title>
          <strong>{{ $t('views.dialogPaymentConfig.title') }} |</strong>
          {{ merchant.name }}
        </v-card-title>

        <v-card-text>

          <!-- CREATION FORM -->
          <v-card outlined class="mb-4" v-if="userLevelAboveAdmin">
            <v-card-text>
              <v-row class="d-flex align-center">
                <v-col cols="1"></v-col>

                <!-- SELECTION OF PAYMENT SERVICE NOTE: For now, only TapPay, LINE Pay and EasyWallet -->
                <v-col cols="3">
                  <v-select :label="$t('views.dialogPaymentConfig.service')" :items="serviceTypeOptions" v-model="serviceType"></v-select>
                </v-col>
                <!-- AUTOCOMPLETE - PAYMENT SERVICES -->
                <v-col cols="4">
                  <v-autocomplete v-model="model.paymentServiceId"
                    :label="$t('views.dialogPaymentConfig.pickHint')" prepend-icon="mdi-cash-register" clearable
                    :loading="loading.paymentServices"
                    :items="paymentServiceOptions" :search-input.sync="model.paymentServiceKeyword"
                    cache-items hide-no-data hide-details solo-inverted> 
                  </v-autocomplete>
                </v-col>
                <!-- AUTOCOMPLETE - BENEFICIARIES -->
                <!-- <v-col cols="3">
                  <v-autocomplete v-model="model.beneficiaryId" :disabled="!model.beneficiaryRequired"
                    label="Pick a Beneficiary" prepend-icon="mdi-bank-outline" clearable
                    :loading="loading.beneficiaries"
                    :items="beneficiaryOptions" :search-input.sync="model.beneficiaryKeyword"
                    cache-items hide-no-data hide-details solo-inverted> 
                  </v-autocomplete>
                </v-col> -->
                <!-- CONFIRM ADD BUTTON -->
                <v-col cols="3">
                  <v-btn x-large rounded color="warning" @click="addConfiguration"
                    :disabled="!model.paymentServiceId">
                    <v-icon left>mdi-plus-box-outline</v-icon>
                    {{ $t('views.dialogPaymentConfig.buttonAddConfig') }}
                  </v-btn>
                </v-col>

                <v-col cols="1"></v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- LIST OF PAYMENT CONFIGURATIONS -->
          <SSRTable ref="pc-list"
            :data-url="loadUrl" :disable-pagination="true"
            :schema="schema">

            <div slot="is_enabled" slot-scope="{item}" class="d-flex justify-center">
              <v-switch inset color="success" label="" hide-details
                v-model="item.is_enabled" @change="togglePaymentConfig(item)">
              </v-switch>
            </div>

            <div slot="service" slot-scope="{item}">
              <div v-if="getPaymentServiceIcon(item.service)">
                <img :src="getPaymentServiceIcon(item.service)" style="height: 26px;">
              </div>
              <div v-else>{{ item.service }}</div>
            </div>

            <div slot="type" slot-scope="{item}">
              <div v-if="getPaymentServiceIcon(item.type)">
                <img :src="getPaymentServiceIcon(item.type)" style="height: 26px;">
              </div>
              <div v-else>{{ item.type }}</div>
            </div>

            <div slot="use_tds" slot-scope="{item}">
              <v-icon v-if="item.use_tds">mdi-check</v-icon>
            </div>

            <div slot="actions" slot-scope="{item}">
              <v-btn rounded small color="error" elevation="0"
                @click="deletePaymentConfig(item)" v-if="userLevelAboveAdmin">
                <v-icon left>mdi-delete</v-icon>
                {{ $t('views.dialogPaymentConfig.buttonDelete') }}
              </v-btn>
            </div>
          </SSRTable>

        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn depressed @click="closeDialog">Close</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import { GetIconMixin } from '@/mixins/get-icons';

import axios from 'axios';

export default {
  name: 'PaymentConfigDialog',
  components: {
    SSRTable
  },
  mixins: [
    GetIconMixin
  ],
  props: {
    show: Boolean,
    merchant: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {

  },

  methods: {
    closeDialog() {
      this.$emit('update:show', false);
    },

    initDialog() {
      // console.warn(`[PaymentConfigDialog] initDialog. Merchant is`, this.merchant);
      this.fetchPaymentServices();
      // this.fetchBeneficiaries();
    },

    fetchPaymentServices() {
      console.warn(`[DEBUG] Fetching payment services (${this.serviceType}) of merchant ${this.merchant['name']}`);
      let orgAlias = this.merchant['organization_alias'];
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${orgAlias}/payment-services`
        + `?includeCollectingAgents=true&limit=-1`
        + (this.serviceType != 'all'? `&service=${this.serviceType}`: '')
      )
      .then(response => {
        this.list.paymentServices = response.data['paymentServices'];
      })
      .catch(err => {
        console.error(`Failed to fetch payment services of ${orgAlias}`, err);
      });
    },
    // fetchBeneficiaries() {
    //   let orgAlias = this.merchant['organization_alias'];
    //   axios.get(
    //     `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${orgAlias}/beneficiaries?limit=-1`
    //   )
    //   .then(response => {
    //     this.list.beneficiaries = response.data['beneficiaries'];
    //   })
    //   .catch(err => {
    //     console.error(`Failed to fetch beneficiaries of ${orgAlias}`, err);
    //   });
    // },

    addConfiguration() {
      // console.warn(`Will add a configuration. Payment Service ${this.model.paymentServiceId} + Beneficiary ${this.model.beneficiaryId}`);
      console.warn(`Will add payment service ${this.model.paymentServiceId} to merchant ${this.merchant['merchant_id']}`);

      // Make the request.
      let merchantId = this.merchant['merchant_id'];
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}/payment-configs`,
        { "paymentServiceId": this.model.paymentServiceId }
      )
      .then(response => {
        this.$notify({
          text: 'Payment configuration added.',
          type: 'success'
        });
        this.$refs['pc-list'].loadData();
      })
      .catch(err => {
        console.error(`Failed to add configuration for ${merchantId}`, err);
        this.$notify({
          text: `Cannot add payment configuration: ${err.message}`,
          type: 'error'
        });
      });
    },
    togglePaymentConfig(pc) {
      let enable = pc['is_enabled'];
      console.warn(`Will ${enable? 'enable': 'disable'} a configuration`, mpcId);
      let mpcId = pc['id'];
      let useTds = pc['use_tds'] === 1;
      let merchantId = this.merchant['merchant_id'];
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}/payment-configs/${mpcId}/enable`,
        {
          "enable": enable, 
          "paymentServiceId": pc['payment_service_id'],
          "useTds": useTds
        }
      )
      .then(response => {
        this.$refs['pc-list'].loadData();
      })
      .catch(err => {
        console.error(`Failed to ${enable? 'enable': 'disable'} configuration`, err);
        pc['is_enabled'] = !enable;
        this.$notify({
          text: `Cannot ${enable? 'enable': 'disable'} "${pc['name']}" for this merchant.`,
          type: 'error'
        });
      });
    },
    deletePaymentConfig(pc) {
      if (!confirm(`Are you sure to delete this payment service for this merchant?`)) return;
      let mpcId = pc['id'];
      let merchantId = this.merchant['merchant_id'];
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}/payment-configs/${mpcId}`
      )
      .then(response => {
        this.$notify({
          text: 'Payment configuration deleted.',
          type: 'warn'
        });
        this.$refs['pc-list'].loadData();
      })
      .catch(err => {
        console.error(`Failed to delete configuration`, err);
        this.$notify({
          text: `Cannot delete payment configuration: ${err.message}`,
          type: 'error'
        });
      });
    }
    
  },

  data() {
    return {
      list: {
        paymentServices: [],
        beneficiaries: []
      },

      serviceTypeOptions: [
        { value: 'all', text: 'All' },
        { value: 'tappay', text: 'TapPay' },
        { value: 'linepay', text: 'LINE Pay' },
        { value: 'easywallet', text: 'EasyWallet' }
      ],
      serviceType: 'all',

      paymentServiceOptions: [],
      beneficiaryOptions: [],
      model: {
        paymentServiceKeyword: '',
        beneficiaryKeyword: '',
        paymentServiceId: 0,
        beneficiaryId: 0,

        beneficiaryRequired: false
      },
      loading: {
        paymentService: false,
        beneficiaries: false
      }

    };
  },

  computed: {
    loadUrl() {
      let merchantId = this.merchant.merchant_id;
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}/payment-configs`;
    },

    schema() {
      return [
        { value: 'is_enabled', text: this.$t('views.dialogPaymentConfig.enabled'), align: 'center', width: '120px' },
        { value: 'name', text: this.$t('views.dialogPaymentConfig.methodName') },
        { value: 'service', text: this.$t('views.dialogPaymentConfig.paymentService'), align: 'center' },
        { value: 'type', text: this.$t('views.dialogPaymentConfig.type'), align: 'center' },
        { value: 'use_tds', text: this.$t('views.dialogPaymentConfig.tds'), attrs: { } },
        { value: 'actions', text: this.$t('views.dialogPaymentConfig.actions'), sortable: false }
      ]
    },

    user() {
      return this.$store.getters.user;
    },
    userLevelAboveAdmin() {
      return [ 'superadmin', 'admin' ].indexOf(this.$store.getters.user['level']) >= 0;
    },
    // orgAlias() {
    //   if (this.$store.getters.user['organization'])
    //     return this.$store.getters.user['organization']['alias'];
    //   return undefined;
    // },
    orgName() {
      if (this.$store.getters.user['organization'])
        return this.$store.getters.user['organization']['name'];
      return undefined;
    }
    
  },

  watch: {
    'merchant': {
      immediate: false,
      handler(merchant) {
        console.warn(`[PaymentConfigDialog] watch merchant changed`, merchant);
        this.initDialog();
      }
    },
    'serviceType': {
      immediate: false,
      handler(v) {
        this.fetchPaymentServices();
      }
    },

    /// When keywords changed, update autocomplete candidates
    'model.paymentServiceKeyword': function(v) {
      let optionMapping = ps => { return { value: ps['id'], text: ps['name'] } };
      if (v && v.length) {
        this.paymentServiceOptions = this.list.paymentServices.filter(ps => {
          return ps['name'].indexOf(v) >= 0;
        })
        .map(optionMapping);
      }
      else {
        this.paymentServiceOptions = this.list.paymentServices.map(optionMapping);
      }
    },
    // 'model.beneficiaryKeyword': function(v) {
    //   let optionMapping = b => { return { value: b['id'], text: `${b['account_name']} - ${b['bank_name']}` } };
    //   if (v && v.length) {
    //     this.beneficiaryOptions = this.list.beneficiaries.filter(b => {
    //       return b['account_name'].indexOf(v) >= 0 || b['bank_name'].indexOf(v) >= 0;
    //     })
    //     .map(optionMapping);
    //   }
    //   else {
    //     this.beneficiaryOptions = this.list.beneficiaries.map(optionMapping);
    //   }
    // },

    // /// When payment-service selection changed, check if beneficiary option is required.
    // 'model.paymentServiceId': function(v) {
    //   let ps = this.list.paymentServices.find(ps => ps['id'] === v);
    //   this.model.beneficiaryRequired = ps && !ps['organization_id'];
    // }
  }
}
</script>

<style scoped>
.v-input--switch {
  margin-top: 0;
}
</style>